import React from "react";
import Layout from "../../components/Layout";

// eslint-disable-next-line
export default () => (
  <Layout>
    <section className="section" style={{
            backgroundImage: "url(/img/topo.svg)",
            backgroundSize: "100%",
            backgroundColor: "#000C29",
            overflow: "visible",
            backgroundRepeat: "no-repeat",
            paddingTop: "2.5em",
            paddingBottom: "5em",
            height: "1400px",
          }}>
      <div className="container">
        <div className="content">
          <div className="columns">
            <div className="column"></div>
              <div className="column is-10">
                <h1 style={{
                      color: "#30CAFF",
                    }}>Thank you!</h1>
                <p style={{
                      color: "white",
                    }}>We'll get back to you with a reply within 48 business hours.</p>
              </div>
            <div className="column"></div>          
          </div>
        </div>
      </div>
    </section>
  </Layout>
);
